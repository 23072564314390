import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import DisturboPageTemplate from '../components/template-disturbo';

const DisturboPage = ({ data }) => {
  const { disturbo } = data;
  const { title, tagTitle, metaDescription, image } = disturbo;
  return (
    <Layout className="home" data={data}>
      <Seo
        title={tagTitle || title}
        ogImage={image.childImageSharp.resize}
        ogType="article"
        description={metaDescription}
        data={data}
      />
      <DisturboPageTemplate {...disturbo} />
    </Layout>
  );
};

export default DisturboPage;
export const query = graphql`
  query DisturboQuery($id: String!) {
    ...HeaderFragment
    ...SeoFragment
    disturbo: disturboJson(id: { eq: $id }) {
      title
      tagTitle
      metaDescription
      text
      image {
        childImageSharp {
          gatsbyImageData(height: 270, layout: CONSTRAINED, placeholder: TRACED_SVG)
          resize(height: 270) {
            src
            width
            height
          }
        }
      }
    }
  }
`;
